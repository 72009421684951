video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.dashboard-container {
    /* background: url('imgs/logo_klein_komplett_einfach_nutzbar.png') center center/cover no-repeat;  */
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    object-fit: contain;
    background-color: #fff;
  }

  .front-logo {
    width: 55%;
    height: auto;
  }

  .dasboard-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .dasboard-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .dashboard-btns {
    margin-top: 32px;
  }
  
  .dashboard-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }

  @media screen and (min-width: 2125px) {
    .front-logo {
      width: 45%;
      height: auto;
    }
  }

  @media screen and (max-width: 1440px) {
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(10%, 70%);
      font-size: 1.5rem;
    }

    .front-logo {
      width: 65%;
      height: auto;
    }
  }
  
  @media screen and (max-width: 960px) {
    .dashboard-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }


    .dashboard-container {
      height: 45vh;
    }
  }
  
  @media screen and (max-width: 768px) {
    .dashboard-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .dashboard-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
    
    .dashboard-container {
      height: 35vh;
    }
      
  }

  @media screen and (max-width: 390px) {
    .dashboard-container {
      height: 35vh;
    }
  }